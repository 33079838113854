import { ChevronForwardOutlineIc, ArrowBackOutlineIc } from '@dsch/dd-icons';
import { useEffect } from 'react';
import styled from 'styled-components';

export type ListItem = {
  value: string;
  displayName: string;
  formattedDisplayName?: string | null;
  group?: string;
  isDisabled?: boolean;
  displayBackIcon?: boolean;
};

export type ListItemVariant = {
  groupDisplayName: string;
  group?: string;
  values: {
    value: string;
    displayName: string;
    formattedDisplayName?: string;
    isDisabled?: boolean;
    displayBackIcon?: boolean;
  }[];
};

export interface CustomSelectProps {
  onSelect: (s: ListItem) => void;
  listData: (ListItem | ListItemVariant)[];
  preselectedOption?: ListItem;
  displayIcon?: boolean;
  hasHeading?: boolean;
  dataTestId?: string;
  selectedItem?: Omit<ListItem, 'value'> & { value: string | null };
  highlightSelected?: boolean;
}

const A11yButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.GREY_DARKER};

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.GREY_LIGHT};
    &:hover {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`;

const List = styled.ul`
  ${({ theme }) => theme.fontSize.M14};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
`;

const ListItem = styled.li<{ isDefault?: boolean; isDisabled?: boolean }>`
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  width: 100%;
  height: 40px;
  text-align: left;
  margin: ${({ isDefault }) => (isDefault ? `0 0 1px` : `1px 0`)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.OFFWHITE};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.WHITE};
    background-image: none;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

const ListHeadingItem = styled.li`
  ${({ theme }) => theme.fontSize.S12};
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  margin: -1px 0;
`;

const SIoChevronForwardOutline = styled(ChevronForwardOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY};
`;

const SArrowBackOutlineIc = styled(ArrowBackOutlineIc)`
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const DefaultText = styled.span`
  display: flex;
`;

function CustomSelect({
  listData,
  onSelect,
  preselectedOption,
  displayIcon,
  hasHeading = true,
  selectedItem,
  highlightSelected = false,
  dataTestId = `custom-select-${listData[0]}`,
}: CustomSelectProps) {
  const defaultItem = hasHeading ? listData[0] : undefined;
  const modifiedListData = hasHeading ? listData.slice(1) : listData;

  const formatDisplayText = (text: string): string => {
    if (highlightSelected && selectedItem?.displayName === text) {
      return `<b>${text}</b>`;
    }
    return text;
  };

  function handleClick(selectedOption: ListItem) {
    onSelect(selectedOption);
  }

  useEffect(() => {
    if (preselectedOption) {
      onSelect(preselectedOption);
    }
  }, []);

  return (
    <List role="listbox" data-testid={dataTestId}>
      {/* Must determine which type of item is being passed: */}
      {defaultItem ? (
        'displayName' in defaultItem ? (
          <ListItem role="option" isDefault key="default-item">
            <A11yButton onClick={() => handleClick(defaultItem)}>
              <DefaultText>
                {defaultItem.displayBackIcon && (
                  <SArrowBackOutlineIc height={16} width={16} />
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      defaultItem.formattedDisplayName ||
                      defaultItem.displayName,
                  }}
                />
              </DefaultText>
            </A11yButton>
          </ListItem>
        ) : (
          <ListItem
            role="option"
            isDefault
            onClick={() => handleClick(defaultItem.values[0])}
            key="default-item"
          >
            <A11yButton>
              <DefaultText>
                {defaultItem.values[0].displayBackIcon && (
                  <SArrowBackOutlineIc height={16} width={16} />
                )}
                {defaultItem.values[0].displayName}
              </DefaultText>
            </A11yButton>
          </ListItem>
        )
      ) : undefined}
      {modifiedListData.map((item: ListItem | ListItemVariant, index) => {
        // ListItem:
        return 'displayName' in item ? (
          item.value ? (
            <ListItem isDefault key={index} role="option">
              <A11yButton
                disabled={!!item.isDisabled}
                onClick={() => handleClick(item)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      item.formattedDisplayName ||
                      formatDisplayText(item.displayName),
                  }}
                />
                &nbsp;
                {displayIcon && (
                  <SIoChevronForwardOutline width="16" height="16" />
                )}
              </A11yButton>
            </ListItem>
          ) : (
            <ListHeadingItem key={index}>{item.displayName}</ListHeadingItem>
          )
        ) : (
          // ListItemVariant:
          [
            <ListHeadingItem key={`group-${index}`}>
              {item.groupDisplayName}
            </ListHeadingItem>,
            ...item.values.map((item, index) => (
              <ListItem role="option" key={`option-${index}`}>
                <A11yButton onClick={() => handleClick(item)}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        item.formattedDisplayName ||
                        formatDisplayText(item.displayName),
                    }}
                  />
                  &nbsp;
                  {displayIcon && (
                    <SIoChevronForwardOutline width="16" height="16" />
                  )}
                </A11yButton>
              </ListItem>
            )),
          ]
        );
      })}
    </List>
  );
}

export { CustomSelect };
