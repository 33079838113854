import { useEffect, createRef } from 'react';
import type { ReactNode } from 'react';
import Modal, { Props } from 'react-modal';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ChevronUpIc, CloseOutlineIc } from '@dsch/dd-icons';

import { theme } from 'helpers/Theme';
import { media } from 'helpers/breakpoints';
import { StickyFooter } from 'components/Toolkit/StickyFooter/StickyFooter';
import { Columns } from 'components/Toolkit/Columns/Columns';
import { disableScroll, enableScroll } from 'helpers/scroll';
import { withOpacity } from 'helpers/Theme/Theme';

type Variant = 'SELECT' | 'DIALOG';

export interface BottomSheetProps extends Props {
  headerText: string;
  variant?: Variant;
  height?: string;
  onApply?: () => void;
  askToClose: () => void;
  testId?: string;
  children?: ReactNode;
  buttonA?: ReactNode;
  buttonB?: ReactNode;
  optionSelected?: boolean;
  resetScrollPosition?: boolean;
  headerClassName?: string;
}

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#__next');

const OverlayStyle = createGlobalStyle`
  .bottom-sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndices.MODAL};
    background-color:rgba(0,0,0,0);
    transition: all 200ms ease-in-out;

    &.ReactModal__Overlay--after-open {
      background-color: ${({ theme }) =>
        withOpacity(theme.colors.GREY_DARKER, 0.8)};
    }

    &.ReactModal__Overlay--before-close {
      background-color:rgba(0,0,0,0);
    }
  }
`;

const SBottomSheet = styled(Modal)<{ height: string }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${({ height }) => height} !important;
  z-index: ${({ theme }) => theme.zIndices.MODAL};
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.default} ${theme.borderRadius.large} 0 0`};
  transform: translateY(100%);
  transition: all 0.2s ease-in-out;

  .ReactModal__Overlay--after-open & {
    transform: translateY(0);
  }

  .ReactModal__Overlay--before-close & {
    transform: translateY(100%);
  }

  ${media.large} {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    border-radius: ${({ theme }) => `${theme.borderRadius.large} `};

    .ReactModal__Overlay--after-open & {
      transform: translate(-50%, -50%);
    }

    .ReactModal__Overlay--before-close & {
      transform: translate(-50%, -50%);
    }
  }

  :focus {
    outline: none;
  }
`;

const SBottomSheetInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SelectHeader = styled.button<{ optionSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.fontSize.M16};
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_LIGHT};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.default} ${theme.borderRadius.default}
    0 0`};
  color: ${({ theme, optionSelected }) =>
    optionSelected ? theme.colors.GREY_DARKER : theme.colors.GREY_DARK};
`;

const DialogHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacing.M24} ${theme.spacing.M24} 0`};

  ${media.large} {
    padding: ${({ theme }) => `${theme.spacing.L48} ${theme.spacing.L48} 0`};
  }
`;
const DialogHeaderText = styled.span`
  ${({ theme }) => theme.fontSize.B18};
  font-weight: bold;
`;

const Body = styled.div<{ padded: boolean }>`
  flex: 1 1 100%;
  height: 100%;
  overflow: auto;
  ${({ padded, theme }) =>
    padded &&
    css`
      padding: ${theme.spacing.M24};

      ${media.large} {
        padding: ${theme.spacing.M24} ${theme.spacing.L48} ${theme.spacing.L48};
      }
    `}
`;

const Close = styled.span`
  top: ${({ theme }) => theme.spacing.M24};
  right: ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  position: absolute;
  padding: 0;
  cursor: pointer;
`;

function BottomSheet({
  headerText,
  height = '70vh',
  children,
  askToClose,
  onApply,
  buttonA,
  buttonB,
  testId = '',
  optionSelected,
  resetScrollPosition = false,
  isOpen,
  headerClassName,
  variant = 'SELECT',
  ...rest
}: BottomSheetProps) {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    isOpen && disableScroll();
    return () => {
      enableScroll();
    };
  }, [isOpen]);

  useEffect(() => {
    if (resetScrollPosition) {
      ref.current?.scrollTo({ top: 0 });
    }
  }, [resetScrollPosition]);

  return (
    <>
      <OverlayStyle />
      <SBottomSheet
        height={height}
        isOpen={isOpen}
        className="bottom-sheet"
        overlayClassName="bottom-sheet-overlay"
        {...rest}
        onRequestClose={askToClose}
        closeTimeoutMS={200}
        testId={testId}
      >
        <SBottomSheetInner data-testid="bottom-sheet-list">
          {variant === 'SELECT' && (
            <SelectHeader
              onClick={onApply}
              data-testid="bottom-sheet-header"
              optionSelected={optionSelected}
              className={headerClassName}
            >
              <span className="bottom-sheet-header-text">{headerText}</span>
              <ChevronUpIc color={theme.colors.BLUE} />
            </SelectHeader>
          )}
          {variant === 'DIALOG' && (
            <DialogHeader>
              <DialogHeaderText>{headerText}</DialogHeaderText>
              <Close onClick={askToClose} data-testid="modal-close">
                <CloseOutlineIc width="16" height="16" />
              </Close>
            </DialogHeader>
          )}
          <Body padded={variant === 'DIALOG'} ref={ref}>
            {children}
          </Body>
          {!!(buttonA || buttonB) && (
            <StickyFooter data-testid="sticky_footer" position="sticky">
              <Columns>
                {buttonA && buttonA}
                {buttonB && buttonB}
              </Columns>
            </StickyFooter>
          )}
        </SBottomSheetInner>
      </SBottomSheet>
    </>
  );
}

export { BottomSheet };
