import { useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { ChevronDownOutlineIc, ChevronUpOutlineIc } from '@dsch/dd-icons';

import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

import getConfig from 'next/config';
import { Loading } from 'components/Toolkit/Loading/Loading';
import { HeightVariant } from 'components/Toolkit/Inputs/types';

export interface DropdownProps {
  id?: string;
  buttonId?: string;
  children: ReactNode;
  expanded: boolean;
  setExpanded: (t: boolean) => void;
  placeholder: string;
  displayedValue?: string;
  isDisabled?: boolean;
  leftBorderOnFocus?: boolean;
  isLoading?: boolean;
  className?: string;
  cssStyles?: FlattenSimpleInterpolation;
  maxHeight?: string;
  resetScrollPosition?: boolean;
  textContainerMaxWidth?: string;
  heightVariant?: HeightVariant;
  dataTestId?: string;
}

type TDropdownContainer = {
  isExpanded: boolean;
  leftBorderOnFocus: boolean;
};
const DropdownContainer = styled.div<TDropdownContainer>`
  border-radius: ${({ theme, isExpanded }) =>
    !isExpanded
      ? theme.borderRadius.default
      : `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`};
  position: relative;
  ${({ theme, isExpanded, leftBorderOnFocus }) =>
    isExpanded &&
    leftBorderOnFocus &&
    `border-left: 1px solid ${theme.colors.GREY}`}
`;

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS },
} = getConfig();

type TSButtonDropdown = {
  selectedValue: boolean;
  expanded: boolean;
  disabled: boolean;
  cssStyles?: FlattenSimpleInterpolation;
  heightVariant?: HeightVariant;
};
export const SButtonDropdown = styled.button<TSButtonDropdown>`
  width: 100%;
  height: ${({ heightVariant, expanded }) =>
    heightVariant === 'LARGE'
      ? `${expanded ? '47px' : '48px'}`
      : `${expanded ? '39px' : '40px'}`};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  border-radius: ${({ theme, expanded }) =>
    expanded
      ? `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`
      : theme.borderRadius.default};
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ selectedValue, theme, disabled }) =>
    !selectedValue
      ? disabled
        ? theme.colors.GREY_LIGHT
        : theme.colors.GREY
      : theme.colors.GREY_DARKER};
  border: ${({ theme, expanded, disabled }) =>
    expanded
      ? '1px solid transparent'
      : disabled
      ? `1px solid ${theme.colors.GREY_LIGHT}`
      : `1px solid ${theme.colors.GREY}`};

  &:disabled {
    cursor: not-allowed;
  }
  ${({ cssStyles }) => cssStyles}
`;

type TSIoChevronDownOutline = { disabled: boolean };
const SIoChevronDownOutline = styled(
  ChevronDownOutlineIc,
)<TSIoChevronDownOutline>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.GREY_LIGHT : theme.colors.GREY_DARKER};
`;

const SIoChevronUpOutline = styled(ChevronUpOutlineIc)`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

type TListContainer = {
  expanded: boolean;
  maxHeight: string;
  heightVariant?: HeightVariant;
};
const ListContainer = styled.div<TListContainer>`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  position: absolute;
  top: ${({ heightVariant }) => (heightVariant === 'LARGE' ? '48px' : '40px;')};
  left: 0;
  right: 0;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.default} ${theme.borderRadius.default}`};
  z-index: 9000;
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
`;

const SLoading = styled(Loading)`
  width: ${({ theme }) => theme.spacing.M16};
  height: ${({ theme }) => theme.spacing.M16};
  margin-right: ${({ theme }) => theme.spacing.S2};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  width: 100%;
`;

const TextContainer = styled.span<{ textContainerMaxWidth: string }>`
  max-width: ${({ textContainerMaxWidth }) => textContainerMaxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

function Dropdown({
  id,
  placeholder,
  children,
  displayedValue,
  expanded,
  setExpanded,
  isDisabled = false,
  leftBorderOnFocus = false,
  isLoading = false,
  className,
  cssStyles,
  maxHeight = '499px',
  resetScrollPosition,
  textContainerMaxWidth = '100%',
  heightVariant = 'DEFAULT',
  dataTestId,
  buttonId,
}: DropdownProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnUpdateOnly(() => {
    if (resetScrollPosition) {
      listRef.current?.scrollTo({ top: 0 });
    }
  }, [resetScrollPosition]);

  useEffect(() => {
    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', () => handleKeyPress);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', () => handleKeyPress);
    };
  }, [expanded]);

  function handleClickOutside(event: MouseEvent) {
    if (
      dropdownRef.current &&
      dropdownRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setExpanded(false);
  }

  function handleKeyPress(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        setExpanded(false);
        break;
      case 'ArrowDown':
        event.preventDefault();
        listRef.current?.scrollBy({ top: 40, left: 0, behavior: 'smooth' });
        break;
      case 'ArrowUp':
        event.preventDefault();
        listRef.current?.scrollBy({ top: -40, left: 0, behavior: 'smooth' });
        break;
      default:
        break;
    }
  }

  const getRightIcon = () => {
    if (isLoading) {
      return <SLoading cdnUrl={CDN_STATIC_ASSETS} />;
    } else if (expanded) {
      return <SIoChevronUpOutline width="1em" height="1em" />;
    } else if (!expanded) {
      return (
        <SIoChevronDownOutline width="1em" height="1em" disabled={isDisabled} />
      );
    }
  };

  return (
    <DropdownContainer
      ref={dropdownRef}
      id={id}
      isExpanded={expanded}
      leftBorderOnFocus={leftBorderOnFocus}
      className={className}
    >
      <SButtonDropdown
        onClick={() => setExpanded(!expanded)}
        selectedValue={Boolean(displayedValue)}
        expanded={expanded}
        disabled={isDisabled}
        cssStyles={cssStyles}
        heightVariant={heightVariant}
        data-testid={dataTestId}
        id={buttonId}
      >
        <TextContainer textContainerMaxWidth={textContainerMaxWidth}>
          {displayedValue || placeholder}
        </TextContainer>
        {getRightIcon()}
      </SButtonDropdown>
      {expanded && <Divider />}
      <ListContainer
        ref={listRef}
        expanded={expanded}
        maxHeight={maxHeight}
        heightVariant={heightVariant}
      >
        {children}
      </ListContainer>
    </DropdownContainer>
  );
}

export { Dropdown };
