import styled, { useTheme } from 'styled-components';
import { SearchOutlineIc, ChevronDownIc, ChevronUpIc } from '@dsch/dd-icons';
import { media } from 'helpers/breakpoints';
import getConfig from 'next/config';
import { Loading } from 'components/Toolkit/Loading/Loading';
import { HeightVariant } from 'components/Toolkit/Inputs/types';
import React from 'react';

type InputButtonVariants = 'SEARCH';

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS },
} = getConfig();

export interface InputButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  placeholder: string;
  isActive: boolean;
  variant?: InputButtonVariants;
  heightVariant?: HeightVariant;
  isLoading?: boolean;
  isDisabled?: boolean;
  textContainerMaxWidth?: string;
  dataTestId?: string;
}

type TSButtonInput = {
  selectedValue: boolean;
  isActive: boolean;
  variant?: InputButtonVariants;
  heightVariant?: HeightVariant;
};
export const SButtonInput = styled.button<TSButtonInput>`
  width: 100%;
  height: ${({ theme, variant, heightVariant }) =>
    heightVariant === 'LARGE' || variant === 'SEARCH'
      ? theme.spacing.L48
      : theme.spacing.L40};
  cursor: pointer;
  display: flex;
  justify-content: ${({ variant }) =>
    variant === 'SEARCH' ? 'flex-start' : 'space-between'};
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  ${({ theme }) => theme.fontSize.M16};
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ selectedValue, theme }) =>
    selectedValue ? theme.colors.GREY_DARKER : theme.colors.GREY_DARK};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: ${({ theme }) => `1px solid ${theme.colors.GREY}`};

  ${media.large} {
    ${({ theme, isActive }) =>
      isActive && `border-bottom: 1px solid ${theme.colors.GREY_LIGHT}`};
    border-radius: ${({ theme, isActive }) =>
      isActive
        ? `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`
        : theme.borderRadius.default};
    border: ${({ theme, isActive }) =>
      isActive ? '0' : `1px solid ${theme.colors.GREY}`};
  }
`;

const SearchIcon = styled(SearchOutlineIc)`
  margin-right: ${({ theme }) => theme.spacing.M16};
`;

const SLoading = styled(Loading)`
  width: ${({ theme }) => theme.spacing.M16};
  height: ${({ theme }) => theme.spacing.M16};
  margin-right: ${({ theme }) => theme.spacing.S2};
`;

const TextAndIconContainer = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const TextContainer = styled.span<{ textContainerMaxWidth: string }>`
  max-width: ${({ textContainerMaxWidth }) => textContainerMaxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

function InputButton({
  onClick,
  value,
  placeholder,
  isActive,
  variant,
  isLoading = false,
  isDisabled = false,
  className,
  textContainerMaxWidth = '100%',
  heightVariant = 'DEFAULT',
  dataTestId,
  ...rest
}: InputButtonProps) {
  const theme = useTheme();

  const getRightIcon = () => {
    const iconColor = isDisabled
      ? theme.colors.GREY_LIGHT
      : theme.colors.GREY_DARKER;
    if (isLoading) {
      return <SLoading cdnUrl={CDN_STATIC_ASSETS} />;
    } else if (isActive) {
      return <ChevronUpIc width="1em" height="1em" color={iconColor} />;
    } else if (!isActive) {
      return <ChevronDownIc width="1em" height="1em" color={iconColor} />;
    }
  };

  return (
    <SButtonInput
      onClick={onClick}
      selectedValue={Boolean(value)}
      isActive={isActive}
      variant={variant}
      disabled={isDisabled || isLoading}
      className={className}
      heightVariant={heightVariant}
      data-testid={dataTestId}
      {...rest}
    >
      <TextAndIconContainer>
        <TextContainer textContainerMaxWidth={textContainerMaxWidth}>
          {variant === 'SEARCH' && <SearchIcon color={theme.colors.BLACK} />}
          {value || placeholder}
        </TextContainer>
        {variant !== 'SEARCH' && getRightIcon()}
      </TextAndIconContainer>
    </SButtonInput>
  );
}

export { InputButton };
